import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 512.000000 512.000000" {...props}>
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M4302 5008 c-7 -7 -17 -13 -22 -13 -5 0 -22 -15 -37 -33 -15 -18 -68
-63 -118 -100 -60 -44 -103 -86 -130 -125 l-39 -58 -17 34 c-35 68 -170 168
-234 173 l-32 3 -6 -93 c-3 -51 -13 -122 -23 -156 l-17 -63 -19 27 c-31 41
-167 176 -179 176 -6 0 -25 11 -43 25 -18 14 -36 25 -40 25 -4 0 -29 18 -56
39 -28 22 -60 43 -73 46 -12 4 -54 20 -94 36 -75 30 -93 34 -93 21 0 -4 63
-73 140 -152 76 -79 137 -147 134 -151 -2 -5 12 -26 32 -48 39 -43 38 -45 -26
-55 -92 -13 -156 -28 -196 -46 -23 -11 -47 -20 -51 -20 -5 0 -20 -8 -33 -17
l-25 -18 50 -7 c28 -4 75 -12 105 -17 97 -18 234 -32 260 -26 22 5 20 1 -15
-30 -22 -19 -57 -44 -77 -55 -44 -24 -55 -38 -34 -46 9 -3 16 -12 16 -20 0
-16 70 -70 119 -91 l34 -15 -54 -22 c-67 -27 -94 -46 -94 -66 0 -8 -23 -30
-52 -50 -29 -19 -52 -38 -53 -42 0 -4 -16 -28 -35 -54 -19 -25 -35 -53 -35
-62 0 -9 -7 -26 -15 -36 -18 -24 -49 -115 -41 -123 5 -5 26 10 114 83 20 17
42 29 49 27 6 -2 31 8 55 24 24 15 50 28 59 29 10 1 30 10 45 21 16 11 34 23
41 27 22 13 2 -66 -50 -189 -27 -66 -77 -205 -111 -308 l-61 -189 -28 22 c-15
12 -25 25 -22 30 3 4 -15 26 -40 48 -25 22 -45 44 -45 50 0 6 -27 36 -60 68
-33 31 -60 65 -60 74 0 10 -20 28 -50 44 -27 15 -50 31 -50 36 0 5 -37 20 -82
35 -67 22 -92 25 -138 20 -50 -6 -61 -4 -94 18 -20 14 -50 28 -65 32 l-29 6
23 52 c13 29 29 82 35 119 6 37 15 79 20 92 6 17 6 27 -1 32 -6 4 -6 11 2 21
10 12 10 16 -2 24 -8 5 -20 9 -28 9 -7 0 -52 12 -99 26 -84 26 -166 29 -227
10 -17 -6 -24 2 -44 45 -14 32 -57 87 -115 148 -51 53 -98 107 -105 120 -13
23 -13 23 -27 3 -7 -11 -20 -40 -29 -64 -8 -25 -32 -82 -53 -127 l-39 -83 -22
36 c-45 73 -113 111 -269 151 -48 12 -94 25 -103 29 -26 11 -30 -29 -13 -141
16 -113 33 -167 69 -229 l24 -41 -64 -5 c-82 -7 -136 -34 -226 -113 -79 -69
-90 -85 -64 -85 24 0 94 -37 153 -82 53 -41 64 -58 37 -58 -34 0 -122 -39
-160 -71 -60 -52 -135 -132 -135 -145 0 -6 -8 -18 -17 -25 -22 -18 -73 -99
-73 -116 0 -10 8 -10 38 3 20 9 49 18 63 19 14 2 36 8 48 14 12 7 25 10 29 7
5 -3 40 11 78 30 38 19 70 30 72 24 2 -6 18 -3 40 6 20 9 49 16 65 15 15 -1
46 8 69 20 38 19 40 20 34 2 -23 -57 -46 -175 -46 -236 0 -62 2 -68 18 -61 9
4 58 11 107 15 168 14 266 51 344 130 l48 49 7 -41 c4 -22 16 -59 27 -81 l20
-40 -33 7 c-18 3 -40 9 -50 11 -26 7 -29 -9 -12 -72 8 -32 19 -99 24 -149 8
-85 40 -198 61 -210 25 -16 4 -24 -49 -19 -42 5 -80 1 -138 -14 -94 -24 -220
-82 -216 -100 1 -7 -9 -17 -23 -22 -34 -13 -32 -23 17 -64 56 -49 135 -78 179
-66 51 13 233 30 336 32 51 0 90 5 87 9 -8 13 -99 11 -257 -7 -204 -23 -278
-12 -344 49 -29 26 -29 35 -1 39 15 2 22 9 22 21 -2 21 44 46 150 82 80 27
226 35 256 14 11 -7 0 -8 -39 -3 -38 4 -52 3 -47 -5 4 -6 28 -11 54 -11 29 0
59 -8 80 -20 17 -11 32 -21 32 -22 0 -2 -64 -4 -143 -4 -78 -1 -162 -7 -187
-13 l-45 -10 60 5 c231 19 300 15 430 -25 80 -25 57 -26 -41 -1 -46 11 -108
19 -149 18 l-70 -1 81 -7 c44 -4 113 -15 152 -25 66 -16 72 -19 72 -42 0 -21
-4 -24 -22 -19 -13 3 -27 6 -33 7 -5 0 -1 4 10 8 29 12 -122 32 -171 23 -33
-6 -25 -8 51 -13 l90 -5 -115 -3 c-63 -2 -146 -7 -185 -11 -42 -4 1 -6 110 -3
184 4 238 -2 243 -29 2 -12 -5 -14 -43 -8 -25 4 -62 4 -83 0 -30 -6 -24 -8 38
-9 l75 -2 -54 -25 c-34 -15 -74 -25 -110 -25 -33 -1 -44 -4 -26 -6 35 -5 59
-23 31 -23 -10 0 -57 9 -103 21 -47 11 -87 18 -90 16 -2 -3 37 -15 88 -27 l91
-23 -31 -30 -30 -31 -53 17 c-33 10 -76 36 -113 67 -32 27 -64 50 -69 50 -12
0 -9 -3 65 -62 31 -25 79 -54 105 -63 27 -10 50 -19 52 -20 1 -1 -13 -28 -32
-59 -30 -50 -60 -127 -126 -331 -26 -80 -26 -85 8 -76 18 5 30 3 37 -5 18 -23
294 47 357 91 46 31 52 31 44 2 -9 -38 13 -148 43 -212 33 -70 78 -128 96
-121 7 2 17 0 22 -5 7 -7 23 17 47 68 21 43 59 112 86 154 27 42 57 93 66 113
10 20 20 36 24 36 5 0 90 -332 90 -353 0 -4 -18 -4 -39 -1 -42 7 -125 -8 -179
-33 -18 -8 -58 -17 -88 -20 -30 -3 -57 -10 -60 -14 -3 -5 -39 -9 -80 -9 -95 0
-94 -13 2 -55 38 -16 70 -33 72 -37 5 -10 91 -48 110 -48 8 0 20 -7 26 -15 6
-8 47 -24 90 -35 44 -11 87 -27 96 -35 9 -8 41 -17 72 -21 49 -6 57 -10 61
-30 4 -20 0 -24 -22 -24 -15 0 -44 -9 -66 -20 -47 -24 -60 -24 -139 -4 -65 17
-106 46 -106 76 0 10 -18 29 -40 44 -34 23 -46 26 -82 19 -24 -4 -63 -7 -88
-6 -24 1 -56 -3 -70 -9 -42 -17 -126 17 -148 60 -9 19 -28 41 -42 48 -22 13
-30 12 -65 -3 -38 -17 -72 -19 -231 -14 -30 1 -78 -7 -119 -21 -58 -19 -80
-34 -148 -99 -43 -42 -83 -85 -87 -96 -15 -38 -318 -186 -430 -210 -19 -4 -39
-10 -43 -12 -30 -18 129 -61 161 -43 9 4 42 16 72 25 43 13 67 29 112 75 51
52 66 61 151 89 52 18 143 56 201 86 108 55 166 69 166 40 0 -8 5 -15 10 -15
6 0 10 -10 10 -23 0 -16 -15 -32 -57 -59 -112 -72 -243 -169 -243 -180 0 -14
152 -2 226 17 63 16 83 26 108 54 20 22 41 27 51 11 3 -5 -8 -16 -26 -25 -17
-9 -28 -19 -25 -22 3 -3 12 0 21 7 18 15 85 23 85 10 0 -5 -10 -11 -22 -13
-16 -3 -12 -5 12 -6 47 -2 77 -20 35 -22 -26 0 -27 -2 -10 -9 23 -10 10 -13
-45 -10 -19 0 -46 -4 -60 -9 -20 -9 -16 -10 23 -5 26 4 47 2 47 -2 0 -20 -92
-66 -149 -74 -33 -5 -68 -14 -78 -20 -17 -11 -17 -11 2 -5 10 3 40 8 65 10
l45 5 -45 -15 c-40 -13 -41 -14 -10 -10 l35 5 -33 -30 c-18 -17 -41 -30 -52
-30 -30 0 -92 -28 -86 -38 5 -8 -34 -32 -50 -32 -10 0 61 93 115 152 41 44 58
57 75 53 11 -3 44 -1 71 5 l50 9 -62 -4 c-35 -3 -63 -3 -63 0 0 2 17 16 37 30
25 17 53 25 83 26 l45 2 -50 7 c-34 4 -53 3 -60 -5 -5 -7 -38 -33 -73 -59 -77
-59 -176 -188 -197 -260 -9 -28 -18 -77 -22 -108 -5 -51 -4 -58 12 -58 9 0 47
30 83 66 43 43 74 67 89 67 13 0 47 21 79 48 61 54 115 83 160 88 7 0 35 17
64 36 29 19 56 32 61 29 21 -12 116 67 147 122 9 16 16 12 65 -29 36 -32 60
-45 71 -41 11 4 35 -13 80 -60 34 -36 66 -66 69 -66 4 0 33 -25 64 -55 32 -30
62 -55 66 -55 5 0 6 24 3 52 -3 29 -8 110 -11 179 -2 70 -9 139 -15 155 -13
33 2 79 31 95 22 11 23 4 11 -51 -5 -19 -5 -46 -1 -60 5 -20 7 -15 8 21 1 62
18 106 43 112 17 5 19 2 14 -16 -4 -12 -8 -42 -11 -67 l-5 -45 14 53 c7 29 17
56 21 60 4 4 5 -4 1 -18 -8 -28 -19 -181 -12 -165 3 6 7 39 11 75 8 89 17 101
49 70 18 -16 26 -33 25 -54 l-1 -31 -7 30 -7 30 -7 -34 c-4 -18 -6 -48 -6 -65
l1 -31 8 30 c7 27 8 25 10 -20 l3 -50 9 70 c5 39 12 72 14 74 2 2 11 -2 19 -9
14 -11 15 -30 10 -114 -3 -56 -1 -117 4 -136 7 -27 9 -9 10 83 1 108 8 136 27
105 8 -13 7 -80 -2 -113 -4 -16 -3 -17 5 -5 6 8 11 30 11 48 0 40 10 41 33 1
11 -20 18 -55 19 -102 1 -63 2 -66 5 -19 2 28 9 52 15 52 7 0 8 -13 5 -36 -5
-32 -4 -34 7 -19 11 16 16 10 44 -58 18 -42 32 -95 33 -119 1 -31 3 -37 7 -20
6 17 14 2 41 -73 18 -53 35 -94 37 -92 3 2 0 16 -6 31 -13 36 -13 49 3 40 6
-4 3 4 -9 17 -23 27 -37 59 -26 59 4 0 19 -7 32 -16 14 -9 7 1 -16 22 -37 35
-74 108 -61 120 3 3 16 1 29 -6 22 -11 22 -10 3 5 -11 8 -25 13 -31 11 -12 -5
-29 22 -20 31 2 3 24 -8 48 -23 26 -17 37 -21 29 -10 -8 10 -33 27 -55 38 -23
12 -35 23 -28 26 7 2 24 -4 38 -13 14 -9 28 -14 30 -12 3 3 -17 15 -45 28 -27
13 -54 34 -60 47 -12 25 -9 27 21 11 11 -6 18 -7 14 -1 -3 5 -19 15 -36 22
-16 7 -30 16 -30 21 0 5 17 2 38 -7 65 -28 69 -25 7 5 -33 17 -53 30 -45 30 8
1 43 -12 78 -29 34 -16 62 -27 62 -24 0 11 -131 66 -144 61 -15 -6 -52 40 -42
50 3 4 23 1 43 -6 21 -7 45 -14 53 -17 8 -3 4 2 -10 10 -14 8 -45 20 -70 26
-28 7 -52 21 -64 37 l-19 26 64 -5 c73 -7 50 7 -30 18 -30 4 -57 14 -67 25
-16 18 -15 19 27 20 37 1 40 2 19 9 -14 4 -42 8 -62 9 -48 1 -57 18 -16 30 30
8 30 9 7 10 -14 1 -30 -4 -35 -9 -7 -7 -16 -4 -28 9 -16 18 -16 20 6 35 13 10
43 16 74 16 40 0 59 -6 90 -28 21 -16 45 -37 53 -46 14 -16 12 -17 -25 -11
-21 4 -53 10 -70 15 -17 4 -37 4 -44 0 -7 -5 9 -11 41 -15 105 -13 125 -26
205 -132 31 -41 56 -92 49 -102 -3 -5 7 -53 21 -108 14 -54 30 -113 34 -130
l8 -33 -28 20 c-41 29 -50 24 -12 -8 30 -26 33 -33 47 -138 7 -60 19 -123 24
-140 l11 -29 -30 35 c-17 19 -35 35 -41 35 -5 0 -34 33 -64 74 -49 67 -54 80
-60 140 -10 106 -14 112 -12 19 2 -97 -2 -98 -56 -6 -15 26 -44 60 -64 75 -50
38 -110 99 -125 126 -14 25 -17 165 -4 212 6 24 5 28 -6 17 -8 -8 -13 -44 -13
-113 0 -92 -1 -98 -15 -77 -32 48 -73 88 -91 88 -10 0 -19 -5 -19 -11 0 -6 8
-8 19 -4 15 5 28 -6 58 -50 42 -62 156 -185 171 -185 16 0 80 -77 105 -127 16
-31 29 -82 37 -143 15 -120 81 -393 92 -382 4 4 -5 62 -21 127 -23 93 -61 307
-61 340 0 18 23 -6 73 -75 32 -44 63 -80 68 -80 6 0 22 -13 36 -30 36 -43 43
-37 27 22 -8 29 -17 76 -20 103 -3 28 -8 53 -10 57 -2 3 -4 32 -5 65 0 32 -14
109 -30 172 -16 62 -29 125 -29 140 0 14 -9 43 -20 63 -17 32 -18 41 -7 80 10
34 23 50 61 76 31 20 60 32 82 32 23 0 37 6 41 16 5 14 18 16 98 10 104 -8
223 8 232 30 3 8 13 14 23 14 10 0 20 5 22 10 2 6 36 16 76 21 86 12 149 33
177 58 11 10 34 22 50 25 17 4 37 13 45 20 8 8 46 19 84 26 81 15 182 54 211
81 11 10 31 29 45 42 14 13 51 40 83 60 92 59 84 62 -135 59 -106 -2 -233 -7
-283 -11 -87 -9 -90 -8 -140 20 -55 31 -158 59 -220 59 -35 0 -101 -14 -160
-35 -11 -3 11 23 48 59 38 36 78 68 89 72 12 3 44 -1 72 -9 79 -24 183 -40
219 -33 17 3 73 10 123 15 71 7 104 16 152 42 34 18 62 37 62 44 0 7 -45 21
-112 35 l-112 23 50 14 c27 7 85 20 128 29 44 8 96 25 115 37 98 60 191 126
183 131 -4 2 -26 1 -48 -4 -22 -5 -86 -11 -143 -14 -58 -3 -126 -13 -153 -22
-27 -9 -52 -14 -55 -10 -8 8 -91 -37 -137 -73 -22 -17 -53 -40 -71 -51 -49
-30 -227 -111 -220 -100 9 15 -8 12 -32 -5 -12 -8 -28 -12 -37 -9 -23 9 -11
21 29 28 19 4 37 11 40 16 4 6 -5 6 -21 0 -15 -6 -29 -8 -32 -5 -3 2 6 9 19
14 14 6 18 10 9 11 -8 1 1 8 20 15 20 8 27 14 17 14 -10 1 -15 6 -12 11 4 7
13 6 27 -1 13 -7 18 -7 14 -1 -3 6 -1 12 6 15 7 2 2 2 -12 0 -24 -4 -24 -4 -6
15 9 11 29 23 44 27 23 6 24 8 10 16 -10 6 -16 19 -14 35 3 22 13 29 85 54
l81 29 -17 -31 c-23 -39 -91 -100 -103 -93 -6 4 -10 2 -10 -2 0 -5 -12 -14
-27 -19 -24 -8 -25 -11 -10 -17 11 -4 16 -3 12 3 -3 6 0 10 9 10 8 0 23 3 33
6 10 4 -13 -23 -51 -60 -37 -36 -64 -66 -60 -66 17 1 73 55 141 137 74 88 100
129 90 140 -7 6 -123 -34 -182 -63 -52 -26 -145 -115 -169 -163 -13 -25 -31
-69 -39 -97 -24 -82 -45 -114 -128 -200 -84 -87 -98 -94 -48 -26 41 57 120
265 105 279 -4 4 -23 -9 -44 -29 -20 -19 -65 -56 -99 -83 -61 -46 -113 -114
-128 -165 -4 -14 -11 -29 -15 -35 -17 -23 -19 -80 -4 -150 10 -48 12 -80 6
-91 -8 -14 -14 -7 -34 37 -29 64 -129 176 -161 181 -20 3 -22 10 -25 73 -2 39
-1 107 3 152 l7 82 70 -6 70 -6 -16 25 c-15 23 -15 36 -5 114 14 106 3 175
-41 258 l-29 54 51 6 c28 4 73 16 101 26 55 21 227 121 227 132 0 4 -10 13
-22 20 -13 7 -23 18 -23 24 0 17 -111 101 -167 127 -42 19 -47 25 -43 47 12
56 29 81 76 110 47 29 51 29 156 26 61 -2 133 -11 165 -21 32 -9 62 -14 68
-10 6 3 22 -2 38 -13 62 -44 151 -82 179 -76 20 4 50 -5 103 -30 65 -31 140
-48 140 -30 0 6 -83 103 -164 190 -144 158 -379 208 -565 121 -24 -11 -68 -45
-98 -74 l-53 -53 21 52 c57 145 180 366 278 498 l55 75 8 -40 c11 -49 -2 -81
-48 -126 -38 -37 -42 -49 -16 -49 29 0 58 27 90 81 36 63 43 53 51 -81 l6 -95
19 34 c20 38 26 123 13 182 l-9 37 41 -11 c38 -10 41 -14 41 -47 1 -41 22
-104 43 -128 8 -9 21 -37 28 -61 7 -24 31 -62 53 -87 46 -49 165 -118 150 -87
-5 10 -9 54 -9 98 0 74 -3 83 -33 124 -17 24 -32 56 -32 70 0 24 2 23 50 -24
63 -62 65 -51 3 16 -62 67 -64 83 -4 51 93 -50 131 -103 150 -207 19 -100 17
-102 -73 -56 -41 20 -52 23 -40 10 23 -26 144 -64 144 -45 0 2 -6 38 -14 79
-16 83 -36 129 -72 168 l-24 25 47 1 c52 1 198 46 219 68 11 10 3 22 -44 67
-71 68 -100 87 -165 108 l-52 17 51 26 c64 32 114 89 138 156 l18 51 32 -25
c68 -51 183 -73 193 -37 3 14 11 18 30 14 23 -4 25 -2 18 14 -23 56 -34 92
-35 113 0 23 3 21 36 -17 19 -23 42 -42 49 -42 8 0 33 -22 57 -50 24 -27 48
-50 55 -50 6 0 18 -8 25 -18 27 -38 69 -84 73 -81 2 3 -7 43 -20 89 -14 47
-27 99 -30 115 -4 17 -15 45 -26 63 -10 18 -19 39 -19 46 0 7 -15 32 -32 56
-18 24 -38 52 -45 63 -6 11 -24 30 -40 43 l-28 23 27 1 c84 1 218 90 263 174
l19 34 -49 57 c-61 72 -113 106 -170 113 -25 2 -45 6 -45 8 0 3 21 30 47 62
58 71 114 188 118 246 4 50 11 46 -118 67 -83 14 -170 4 -230 -28 -16 -8 -31
-13 -33 -10 -3 2 1 36 8 75 9 54 9 85 0 133 -13 70 -22 85 -40 67z m30 -123
c2 -140 -66 -309 -171 -428 -34 -37 -61 -62 -61 -54 0 8 23 47 51 88 47 69 40
66 -17 -6 -13 -16 -24 -25 -24 -18 0 7 29 50 64 97 59 77 113 166 101 166 -2
0 -12 -15 -21 -32 -9 -18 -43 -68 -75 -110 -64 -82 -100 -153 -92 -175 3 -7 0
-13 -6 -13 -6 0 -11 8 -12 18 0 14 -2 14 -8 -3 -5 -13 -8 2 -7 40 2 76 26 127
121 255 75 100 88 121 105 164 15 41 4 30 -19 -18 -12 -24 -53 -86 -92 -137
-98 -129 -126 -189 -144 -304 -6 -37 -8 -38 -21 -20 -8 10 -13 33 -13 49 l2
31 6 -35 c7 -33 7 -32 15 25 9 68 34 137 72 196 14 23 21 38 15 34 -6 -3 -24
-29 -40 -56 -15 -27 -30 -46 -34 -43 -3 3 -3 -5 0 -18 4 -19 3 -20 -6 -8 -9
12 -10 9 -5 -15 4 -23 3 -26 -5 -15 -8 11 -9 5 -6 -25 4 -30 3 -34 -4 -15 -7
22 -9 19 -19 -30 -10 -55 -11 -55 -10 40 0 52 -3 107 -7 122 -14 52 59 141
214 261 81 62 67 39 -31 -53 -70 -65 -179 -213 -166 -226 3 -2 23 26 44 63 23
41 78 108 137 168 54 55 110 113 124 128 l27 28 9 -23 c4 -13 9 -54 9 -93z
m-1194 45 c35 -16 66 -29 70 -28 16 5 52 -24 66 -54 22 -44 13 -46 -26 -7 -18
18 -37 32 -42 31 -5 -2 -16 4 -25 11 -9 8 -27 20 -41 26 l-25 12 25 -20 c14
-11 30 -21 35 -21 6 0 3 -5 -5 -10 -12 -7 -8 -11 18 -16 24 -5 30 -9 20 -16
-10 -7 -10 -9 2 -7 32 3 45 -2 25 -10 -17 -7 -14 -10 21 -20 23 -6 47 -18 54
-26 11 -13 8 -14 -21 -10 -29 4 -30 3 -9 -4 14 -5 18 -10 10 -11 -11 -1 -9 -4
5 -10 27 -12 43 -12 25 0 -13 8 -12 10 3 10 11 0 31 -10 45 -21 l27 -22 -45 6
c-45 6 -41 3 40 -22 22 -6 24 -9 8 -6 -41 8 -34 -3 10 -15 21 -5 49 -21 63
-36 l24 -26 -40 7 c-22 4 -49 9 -60 11 -16 4 -17 3 -5 -4 8 -6 38 -13 66 -17
41 -5 59 -14 85 -41 l33 -34 -30 -1 -29 -1 33 -8 c46 -11 52 -20 13 -20 -32 0
-167 57 -158 66 3 3 26 0 51 -7 26 -6 49 -9 52 -6 3 2 -19 10 -48 17 -61 15
-99 32 -118 55 -19 22 -8 27 39 16 22 -5 39 -5 36 -1 -3 4 -21 11 -41 14 -35
7 -124 77 -124 99 0 11 -53 64 -101 102 -43 34 -102 105 -88 105 8 0 42 -13
77 -30z m617 -125 c21 -24 48 -71 61 -102 13 -32 24 -51 24 -43 0 31 -53 127
-93 168 -22 23 -35 42 -28 42 23 0 121 -60 157 -97 59 -59 74 -100 74 -205 0
-51 4 -109 10 -127 11 -39 12 -36 -16 -45 -18 -5 -22 -3 -22 16 -1 77 -23 250
-35 279 -8 19 -25 43 -37 54 -21 18 -21 16 3 -16 32 -42 45 -102 49 -226 2
-50 6 -86 10 -80 4 7 8 8 8 2 0 -5 -4 -16 -9 -23 -9 -16 -41 53 -41 91 0 12
-5 29 -11 37 -11 17 -2 -49 13 -89 13 -35 2 -38 -16 -4 -8 15 -15 24 -16 20 0
-5 5 -17 11 -29 20 -37 2 -29 -25 12 -14 22 -33 64 -41 93 -8 29 -14 41 -14
27 0 -65 64 -172 99 -165 17 3 19 1 10 -16 -15 -27 -43 -10 -80 48 -36 57 -49
94 -59 175 l-8 63 -2 -51 c-1 -66 21 -140 59 -192 33 -46 25 -59 -10 -14 -12
15 -17 18 -11 7 13 -28 48 -58 59 -51 5 3 9 -1 9 -10 0 -30 -100 19 -146 72
-55 62 -64 118 -36 210 9 27 18 90 22 141 7 106 13 108 78 28z m923 -61 c12
-3 22 -10 22 -15 0 -5 -42 -51 -92 -102 -76 -76 -109 -102 -180 -138 -48 -24
-85 -45 -83 -47 2 -3 44 15 92 38 73 36 103 58 176 131 48 49 89 89 92 89 7 0
-20 -71 -48 -126 -42 -82 -114 -170 -172 -209 -95 -63 -255 -105 -268 -71 -4
9 -6 17 -5 19 1 1 -4 12 -11 25 -11 20 -9 24 24 43 43 26 46 35 4 14 -17 -9
-33 -14 -36 -12 -8 8 76 53 162 88 69 28 155 75 155 85 0 2 -30 -13 -67 -32
-102 -51 -226 -105 -231 -99 -3 3 38 25 91 50 54 25 97 47 97 50 0 3 -26 -7
-57 -21 -32 -15 -66 -28 -75 -31 -34 -10 -18 7 30 31 26 14 43 25 39 26 -9 0
-82 -36 -115 -58 -15 -10 -19 -18 -12 -22 7 -4 1 -14 -17 -25 l-28 -18 19 21
c11 12 14 22 8 22 -5 0 -22 -14 -36 -31 -14 -17 -26 -26 -26 -22 0 5 12 20 26
34 14 13 39 43 56 66 33 49 52 60 182 107 52 19 107 42 123 51 15 9 19 13 8 9
-11 -4 -74 -26 -139 -50 -66 -24 -121 -42 -123 -40 -3 2 20 15 49 30 58 28 59
35 2 11 -27 -11 -35 -12 -31 -2 39 98 44 103 137 141 19 7 227 0 258 -10z
m-1038 -268 c0 -7 19 -34 41 -60 l42 -47 -54 5 c-40 4 -46 3 -24 -3 17 -5 48
-9 70 -10 47 -2 79 -16 55 -25 -8 -3 -53 -10 -100 -15 l-85 -10 90 4 c50 3
106 6 125 8 19 3 24 2 10 -1 -25 -5 -25 -5 5 -12 26 -5 21 -8 -40 -20 -38 -7
-124 -17 -190 -20 l-120 -6 102 -2 c56 -1 136 5 180 13 94 18 110 18 63 0 -25
-10 -29 -14 -15 -15 27 -2 -96 -38 -165 -49 -53 -8 -54 -9 -13 -10 22 0 70 8
105 19 34 11 74 19 88 19 17 -1 11 -5 -20 -13 -25 -7 -74 -26 -110 -42 -78
-35 -146 -36 -230 -5 -62 23 -146 87 -100 76 158 -38 212 -42 317 -24 92 16
72 21 -34 9 -72 -8 -109 -7 -175 5 -78 13 -158 39 -158 50 0 2 13 11 30 20 16
8 58 41 92 72 35 31 86 66 113 79 56 27 105 31 105 10z m-1720 -86 c0 -5 41
-53 90 -105 116 -121 163 -211 163 -313 1 -56 -4 -76 -28 -120 -53 -98 -78
-96 -52 6 18 69 21 149 7 192 -4 15 -6 -4 -3 -45 3 -38 1 -86 -5 -105 -9 -31
-10 -32 -12 -10 l-2 25 -8 -25 c-5 -15 -7 4 -3 50 6 90 -14 195 -51 269 -37
72 -41 64 -6 -11 42 -91 54 -182 40 -294 -10 -72 -16 -93 -32 -103 -20 -13
-21 -11 -14 41 4 29 8 89 10 133 3 77 3 78 -4 15 -5 -54 -7 -58 -8 -22 -3 67
-29 181 -67 291 -36 104 -42 141 -25 141 6 0 10 -4 10 -10z m2718 -25 c38 -16
108 -78 144 -128 l20 -27 -37 -50 c-67 -91 -163 -140 -275 -140 l-56 0 -34 53
c-26 38 -50 60 -90 81 -33 17 -44 26 -27 22 15 -4 80 -11 145 -16 64 -5 128
-12 142 -15 14 -2 7 2 -15 9 -22 8 -67 15 -100 16 -33 1 -82 5 -110 9 l-50 7
66 8 c37 5 120 12 185 16 100 7 85 8 -91 5 -172 -3 -193 -2 -115 5 52 5 109
14 125 20 l30 10 -30 -5 c-86 -13 -215 -17 -215 -7 0 7 15 12 33 12 18 0 77
11 131 25 54 15 123 30 155 34 51 8 52 9 16 10 -22 0 -94 -12 -160 -29 -66
-16 -138 -29 -160 -29 l-40 1 48 8 c79 14 149 40 202 75 57 38 104 44 163 20z
m-2710 -127 c45 -138 67 -254 59 -304 -4 -24 -2 -32 5 -28 8 5 9 -1 5 -17 -4
-13 -7 -39 -7 -58 0 -19 -3 -31 -7 -27 -5 4 -11 69 -14 144 -4 75 -12 148 -18
162 l-12 25 7 -25 c4 -14 8 -85 9 -159 2 -131 2 -133 -16 -110 -42 54 -63 114
-69 194 -5 76 -6 78 -11 32 -8 -62 5 -119 41 -184 17 -29 25 -55 20 -58 -15
-9 -84 66 -111 121 -19 39 -24 64 -24 124 1 68 5 85 50 182 28 59 51 108 52
108 1 0 20 -55 41 -122z m-300 28 c65 -34 142 -121 142 -161 0 -22 -6 -19 -47
26 -20 22 -46 45 -57 51 -12 5 -5 -3 15 -20 44 -38 93 -108 84 -121 -3 -6 -1
-21 6 -33 6 -13 -9 -2 -33 25 -25 26 -48 47 -53 47 -4 0 10 -19 32 -43 21 -23
59 -71 83 -107 l44 -65 -35 26 c-18 14 -41 29 -49 34 -13 8 -13 7 -1 -8 21
-26 5 -21 -60 19 -55 35 -122 98 -162 154 l-17 25 14 -30 c27 -56 86 -113 176
-169 93 -58 106 -64 94 -44 -4 7 -3 8 5 4 6 -4 9 -11 6 -16 -5 -9 45 -46 58
-42 4 1 3 -7 -3 -18 -12 -22 -25 -26 -35 -10 -3 6 0 10 7 10 16 1 -15 26 -57
46 -27 13 -28 13 -10 0 11 -8 29 -22 40 -31 l20 -17 -20 6 c-20 6 -20 6 -1 -9
11 -8 17 -17 14 -21 -7 -7 -78 29 -137 69 -24 17 -41 25 -37 19 19 -32 -21 -5
-78 51 -40 39 -76 88 -99 132 -20 39 -36 66 -37 62 -1 -24 70 -139 116 -187
30 -31 51 -60 47 -64 -4 -4 1 -4 12 0 11 4 17 4 13 -1 -4 -4 -3 -14 3 -21 8
-10 16 -11 31 -3 11 6 22 8 26 5 3 -3 0 -6 -7 -6 -7 0 -9 -4 -6 -10 9 -15 27
-12 19 3 -4 7 -3 9 2 4 5 -5 9 -14 9 -20 0 -5 6 -12 14 -15 8 -3 11 0 7 7 -5
7 -1 9 9 4 14 -5 14 -8 3 -15 -9 -7 -7 -8 7 -4 11 3 26 2 33 -3 22 -13 -16
-23 -40 -10 -11 6 -22 8 -26 5 -3 -4 9 -12 26 -18 18 -7 32 -16 32 -21 0 -5
-21 3 -47 18 -57 33 -72 45 -54 45 7 0 9 5 6 10 -3 6 -11 10 -16 10 -6 0 -7
-4 -4 -10 13 -21 -15 -8 -72 34 -69 50 -120 110 -156 182 -28 56 -60 205 -55
257 3 28 12 15 75 -114 72 -147 132 -227 193 -259 14 -6 2 9 -27 34 -44 39
-143 177 -143 199 0 4 -20 48 -45 98 -25 50 -45 92 -45 93 0 6 223 -55 248
-68z m2158 -63 c-15 -4 -16 -7 -5 -15 10 -7 7 -8 -11 -4 -14 3 -32 1 -40 -5
-11 -8 -9 -9 10 -4 14 4 -2 -10 -35 -32 -86 -57 -187 -171 -185 -210 1 -4 10
10 22 32 51 97 225 224 288 210 17 -4 30 -11 30 -16 0 -14 -28 -11 -33 4 -4 7
-6 6 -6 -5 -1 -9 -22 -28 -48 -43 -67 -38 -140 -111 -169 -167 -25 -50 -78
-207 -72 -214 2 -2 17 36 32 84 35 108 54 145 101 200 26 30 42 41 55 37 10
-3 21 -1 25 4 3 7 0 8 -9 5 -32 -12 -15 6 29 32 25 14 49 24 52 20 4 -3 2 -6
-3 -6 -5 0 -20 -10 -34 -21 l-25 -20 32 15 c40 20 40 20 46 -4 3 -11 1 -17 -4
-14 -14 8 -103 -61 -141 -109 -25 -33 -15 -26 46 31 74 69 121 97 80 50 -19
-23 -19 -23 2 -5 22 18 54 23 54 8 0 -5 -24 -31 -52 -56 -29 -26 -43 -42 -31
-36 12 6 25 16 28 21 3 5 15 15 26 21 18 9 19 8 9 -8 -7 -10 -29 -31 -50 -47
-21 -16 -56 -47 -77 -69 -28 -30 -20 -25 32 19 l70 58 3 -42 c3 -35 -2 -49
-27 -80 -16 -21 -37 -54 -46 -74 -11 -21 -33 -45 -57 -58 l-38 -22 6 44 c8 51
27 78 95 136 28 23 46 42 41 42 -6 0 -38 -25 -70 -55 -33 -30 -64 -55 -70 -55
-6 0 -16 -17 -22 -37 -24 -78 -34 -95 -76 -124 -39 -26 -44 -28 -44 -11 0 26
93 178 158 259 l54 68 -55 -57 c-30 -31 -86 -108 -123 -170 l-69 -113 -8 75
c-5 41 -9 114 -10 161 -1 78 2 92 31 152 22 43 52 83 90 118 58 54 161 110
197 108 19 -1 19 -2 1 -6z m497 -48 c54 -23 104 -71 121 -118 9 -23 20 -102
26 -174 5 -73 17 -149 25 -169 8 -21 13 -39 11 -42 -3 -2 -21 5 -41 17 l-37
21 21 -26 c11 -14 18 -30 15 -35 -10 -16 -68 -9 -119 15 -58 27 -90 59 -82 81
5 12 -2 15 -34 15 -25 0 -38 4 -34 10 15 24 6 157 -14 214 -20 58 -18 75 12
76 15 0 48 -67 73 -150 28 -90 55 -150 85 -189 29 -36 23 -17 -11 36 -17 26
-42 86 -57 133 -14 47 -36 107 -50 134 -13 26 -20 45 -15 42 6 -3 12 -1 14 6
2 6 17 -6 33 -28 39 -52 50 -64 35 -36 -6 13 -9 26 -6 29 7 7 49 -80 80 -167
26 -71 71 -160 82 -160 3 0 -6 19 -20 43 -14 23 -39 83 -56 132 -37 104 -67
160 -106 195 -15 14 -24 19 -19 11 5 -10 4 -12 -4 -7 -16 10 17 66 36 59 7 -3
11 -9 8 -13 -3 -5 8 -23 25 -42 17 -18 39 -58 50 -88 11 -30 25 -64 30 -75 9
-17 9 -16 4 5 -17 65 -49 135 -78 167 -17 20 -24 34 -16 31 8 -2 30 -24 48
-47 17 -23 32 -39 32 -35 0 4 -12 22 -26 41 -26 34 -27 54 -1 37 7 -5 -1 3
-18 18 -16 15 -46 31 -65 36 -23 7 -28 10 -14 11 12 1 38 -6 57 -14z m-1858
-84 c55 -16 99 -33 98 -38 -2 -13 -111 -185 -131 -206 -28 -32 -94 -74 -142
-90 -25 -9 -36 -16 -25 -16 29 -1 101 34 146 70 21 16 61 68 89 115 28 46 56
84 62 84 6 0 7 -8 3 -17 -3 -10 -13 -55 -22 -101 -31 -165 -119 -242 -275
-242 -44 0 -58 3 -58 15 0 8 15 19 33 24 l32 9 -35 2 c-29 1 -25 4 28 15 66
14 138 51 163 81 8 10 -6 4 -31 -13 -79 -54 -200 -83 -200 -48 0 15 -10 19
-44 15 -11 -1 -15 3 -12 9 4 6 13 11 19 11 46 1 249 121 233 138 -3 2 -24 -12
-48 -31 -43 -35 -161 -97 -185 -97 -22 0 -14 19 26 59 24 24 82 60 155 94 64
31 116 59 116 61 0 7 -186 -79 -218 -100 -31 -20 -34 -16 -21 30 8 30 18 41
52 57 23 10 36 19 29 19 -7 0 -26 -7 -42 -15 -17 -9 -30 -11 -30 -6 0 19 59
41 137 52 43 6 80 13 83 16 9 9 -135 -9 -175 -23 -21 -7 -40 -12 -41 -11 -1 1
-6 19 -12 39 -10 38 -6 48 28 61 36 13 122 4 215 -22z m1016 -77 c-16 -33 -30
-55 -31 -51 0 4 7 21 15 37 27 51 8 33 -30 -30 -38 -62 -112 -124 -137 -115
-8 3 -32 -10 -55 -30 -23 -19 -45 -35 -49 -35 -4 0 -18 -10 -31 -22 -29 -27
-30 -9 -3 47 26 54 66 93 81 78 8 -8 8 -17 0 -31 -6 -11 -11 -24 -11 -29 1 -4
14 18 31 50 31 60 56 77 45 30 -5 -21 -4 -24 4 -13 9 13 10 13 10 0 0 -13 1
-13 10 0 6 8 8 26 5 40 -5 20 1 31 24 48 41 30 44 28 26 -15 -8 -20 -13 -38
-11 -40 2 -2 12 16 21 42 10 25 22 48 26 51 5 3 4 -7 -1 -21 -17 -44 -10 -43
10 2 11 24 30 47 47 55 15 8 29 13 31 12 2 -1 -10 -28 -27 -60z m-1920 -114
l54 -51 -44 16 c-24 8 -51 15 -60 15 -9 -1 19 -12 62 -27 43 -14 76 -28 73
-30 -2 -3 -37 5 -78 16 -101 28 -156 34 -203 23 -37 -9 -33 -10 40 -11 47 -1
111 -9 153 -21 39 -11 84 -20 99 -20 16 0 37 -7 47 -15 18 -14 16 -15 -30 -17
l-49 -1 60 -8 c52 -6 47 -7 -34 -5 -52 1 -85 -1 -75 -5 10 -4 51 -8 89 -8 145
-2 185 -24 89 -49 -58 -16 -176 -10 -249 12 -27 9 -51 13 -53 11 -10 -9 111
-38 188 -43 84 -7 85 -7 52 -21 -33 -14 -125 -11 -167 5 -11 4 -17 3 -12 -1 4
-5 33 -15 65 -22 l57 -13 -65 4 c-53 4 -83 14 -165 55 -55 28 -91 43 -80 35
40 -32 184 -96 227 -101 69 -8 71 -9 51 -39 -16 -25 -21 -26 -73 -21 -71 6
-118 30 -200 103 -36 32 -89 68 -119 82 -46 21 -51 26 -36 35 34 19 183 1 305
-38 105 -33 211 -47 228 -31 3 3 -19 6 -50 6 -35 0 -102 14 -189 41 -101 30
-153 41 -212 42 l-79 2 34 28 c101 86 164 116 249 117 42 0 52 -5 100 -50z
m2583 -2 c-9 -45 -34 -99 -58 -125 -25 -29 -40 -30 -25 -2 8 15 8 19 0 15 -18
-11 -13 0 19 47 46 69 36 69 -12 1 -24 -34 -57 -76 -74 -95 -36 -40 -86 -84
-65 -57 7 9 10 22 6 28 -4 6 -2 9 3 8 5 -2 32 29 61 67 45 59 48 65 17 36 -33
-32 -52 -33 -23 -1 6 8 2 6 -10 -4 -24 -20 -27 -18 -17 9 11 29 132 100 177
104 5 1 5 -14 1 -31z m-218 -38 c-24 -60 -18 -63 10 -5 l25 50 -6 -53 c-8 -69
-41 -159 -60 -159 -10 0 -8 10 11 42 13 23 24 48 24 56 -1 8 -10 -4 -21 -26
-26 -53 -40 -66 -58 -55 -13 7 -13 12 -1 37 8 15 14 33 15 39 0 6 5 18 11 25
16 21 26 39 21 39 -12 0 -82 -97 -93 -129 -10 -27 -13 -30 -14 -13 0 37 45
115 95 161 26 23 49 40 52 37 3 -3 -2 -23 -11 -46z m659 -59 c6 -38 8 -41 21
-24 7 10 14 15 15 11 1 -5 3 -12 4 -18 1 -5 4 -22 5 -37 2 -26 2 -26 10 7 5
19 6 39 3 44 -4 5 8 6 27 2 31 -6 49 -20 50 -38 0 -8 -41 -5 -56 4 -6 4 -11
-7 -11 -25 -2 -26 0 -28 7 -12 8 18 9 16 10 -10 2 -22 4 -25 7 -10 4 16 10 11
29 -25 13 -25 22 -36 21 -25 -1 11 -7 27 -12 35 -23 35 -33 60 -25 60 18 0 40
-32 50 -73 6 -23 15 -56 19 -73 8 -27 -5 -18 -95 70 -90 88 -104 107 -110 143
-3 23 -15 50 -25 59 -11 9 -19 24 -19 33 1 33 68 -55 75 -98z m-1078 -163 c-4
-45 4 -61 18 -38 4 6 16 8 27 5 12 -4 27 0 39 11 10 9 19 13 19 7 0 -5 -9 -18
-21 -29 -33 -29 -62 -94 -63 -140 -1 -33 -10 -53 -47 -100 -81 -104 -151 -216
-226 -359 -41 -77 -71 -129 -68 -115 3 14 23 86 44 160 37 128 40 135 66 140
35 6 43 25 16 40 -12 6 -21 19 -21 29 0 36 72 258 133 410 l61 154 14 -64 c7
-35 11 -85 9 -111z m698 76 c-35 -32 -36 -33 -7 -14 18 12 35 20 38 17 11 -11
-41 -121 -74 -154 -38 -38 -116 -78 -172 -87 -19 -3 -29 -3 -22 0 6 3 10 10 7
15 -4 5 1 6 9 3 19 -7 108 40 138 74 l23 26 -37 -27 c-59 -43 -53 -32 19 38
38 35 65 64 60 65 -4 0 -36 -27 -71 -59 -73 -68 -162 -119 -179 -102 -8 8 -5
17 11 33 11 13 20 28 19 33 -1 6 21 23 48 38 28 15 39 25 25 22 -37 -10 -102
-57 -108 -77 -7 -28 -22 -21 -20 10 1 15 15 43 32 61 l31 35 -37 -28 c-21 -15
-38 -23 -38 -19 0 14 72 79 107 97 47 23 99 34 173 35 l65 0 -40 -35z m-1620
-26 c18 -13 73 -62 124 -109 l91 -87 -63 -11 c-91 -16 -254 -14 -312 4 -64 19
-70 12 -8 -10 69 -24 184 -29 285 -12 50 8 92 10 101 5 17 -9 50 0 42 12 -5 7
25 21 73 35 13 4 22 9 19 11 -2 2 -27 -3 -56 -12 -48 -14 -55 -14 -77 1 -13 8
-24 18 -24 21 0 2 19 14 43 25 45 21 24 19 -30 -2 -28 -11 -34 -11 -52 6 -26
22 -15 39 35 55 20 7 33 14 31 17 -3 2 -27 -5 -52 -16 -57 -25 -65 -26 -65 -5
0 10 6 13 18 10 9 -3 24 -1 32 4 16 10 13 12 -39 14 -17 0 -27 -3 -23 -9 3 -5
-2 -3 -13 5 -17 14 -15 16 30 24 33 6 54 5 67 -3 10 -6 25 -11 35 -11 24 0 83
-23 83 -32 0 -4 -8 -9 -17 -10 -29 -2 -66 -7 -93 -11 -14 -3 -29 -5 -35 -6 -5
-1 -3 -5 4 -10 9 -6 22 -6 32 0 23 12 32 12 24 -1 -3 -5 10 -3 29 5 20 8 45
15 57 15 30 0 118 -50 113 -64 -3 -7 22 -38 55 -70 34 -32 55 -59 48 -61 -7
-2 -21 -6 -32 -8 -14 -3 -12 -5 8 -6 34 -1 127 -69 127 -93 0 -9 25 -35 55
-57 31 -21 51 -42 46 -45 -11 -7 -104 6 -167 23 -51 14 -66 26 -103 86 -17 28
-31 46 -31 42 0 -12 31 -70 49 -91 15 -18 15 -19 -14 -12 -16 4 -53 21 -81 37
-54 30 -82 72 -89 130 -2 16 -7 34 -10 39 -9 14 -25 12 -23 -2 7 -32 -269
-142 -279 -112 -12 33 -21 36 -42 15 -16 -16 -28 -20 -49 -15 -29 7 -82 32
-82 38 0 3 -3 11 -6 20 -6 14 -2 14 36 1 23 -8 52 -15 64 -14 12 1 4 5 -17 10
-43 10 -121 48 -114 55 3 3 18 -1 34 -9 45 -23 128 -37 211 -35 l77 1 -95 7
c-52 4 -120 14 -151 23 -59 17 -143 72 -134 86 3 5 -10 18 -28 30 -41 26 -31
26 35 0 102 -41 182 -59 253 -58 l70 2 -90 7 c-66 6 -117 18 -193 46 -56 21
-101 43 -99 49 2 5 -3 15 -12 22 -9 7 18 -1 59 -17 41 -15 98 -31 125 -34 l50
-6 -60 15 c-33 9 -80 23 -105 33 l-45 17 40 6 c53 9 119 -5 239 -53 54 -21
100 -37 102 -35 5 4 -187 81 -231 92 -21 6 -25 9 -12 12 9 3 41 -2 69 -11 64
-19 75 -14 16 7 -34 12 -38 16 -18 16 30 1 108 -34 184 -83 31 -20 56 -33 56
-31 0 14 -156 105 -200 116 l-50 13 23 24 c27 29 73 25 122 -10z m1278 -6 c19
-21 32 -99 23 -136 -7 -28 -53 -86 -67 -86 -6 0 -7 6 -3 13 4 6 -6 0 -23 -15
-49 -46 -49 -31 1 22 25 27 46 53 46 57 -1 4 -15 -9 -32 -30 -17 -21 -40 -43
-51 -49 -14 -7 -10 2 15 33 19 24 41 55 47 69 l12 25 -18 -25 c-33 -46 -82
-95 -83 -82 0 18 18 51 44 82 16 19 13 18 -13 -5 -32 -29 -33 -29 -26 -6 5 17
4 21 -4 16 -8 -4 -6 7 3 30 9 21 16 39 16 41 0 6 82 64 90 64 4 0 14 -8 23
-18z m-2093 -21 c0 -11 -14 -28 -34 -40 -42 -27 -155 -146 -180 -192 -24 -43
-12 -31 48 49 27 35 71 81 98 103 50 39 51 40 65 19 8 -12 13 -22 12 -23 -2
-2 -26 -20 -54 -41 -94 -74 -147 -131 -181 -198 -19 -38 -33 -68 -30 -68 2 0
24 35 48 78 28 47 65 93 96 120 69 58 75 53 13 -12 -28 -30 -51 -57 -51 -59 0
-3 27 21 60 52 33 31 77 67 99 79 39 22 39 22 34 2 -3 -11 -22 -34 -42 -51
-21 -17 -28 -27 -16 -23 25 9 16 0 -57 -55 -85 -64 -71 -60 25 6 113 79 137
99 138 114 0 11 2 11 6 1 2 -6 9 -10 14 -6 5 3 9 0 9 -5 0 -6 -7 -11 -15 -11
-8 0 -12 -6 -9 -14 3 -9 -13 -33 -43 -62 -41 -40 -43 -44 -13 -24 26 18 31 20
20 6 -8 -10 -54 -45 -102 -77 -47 -32 -89 -63 -93 -69 -9 -15 141 83 193 126
23 19 42 40 42 45 0 6 -5 7 -12 3 -6 -4 -8 -3 -5 3 8 13 78 3 84 -13 7 -20
-40 -96 -84 -134 -21 -18 -69 -48 -108 -67 -38 -19 -59 -31 -45 -27 95 25 199
104 233 177 10 20 24 37 32 37 21 0 19 -6 -18 -80 -41 -82 -103 -131 -207
-164 -75 -23 -250 -43 -250 -28 0 19 107 126 168 169 82 57 100 74 47 43 -77
-45 -146 -103 -198 -169 l-52 -65 -3 49 c-4 64 23 185 58 260 40 84 129 189
193 225 61 35 67 36 67 11z m-404 -60 c-16 -6 -11 -10 26 -19 26 -7 50 -16 53
-22 3 -5 15 -10 26 -10 23 0 24 -10 3 -36 -15 -19 -16 -19 -10 1 7 23 3 18
-51 -58 -33 -47 -95 -86 -125 -80 -10 3 -45 -9 -78 -26 -33 -17 -67 -28 -75
-25 -8 4 -22 1 -30 -6 -8 -6 -20 -10 -27 -7 -7 2 -34 -6 -60 -20 -27 -13 -48
-21 -48 -18 0 14 25 53 71 112 27 35 49 67 49 72 0 5 24 26 53 47 28 20 57 44
64 51 17 21 103 52 143 52 27 0 30 -2 16 -8z m939 -132 c49 -55 98 -160 102
-223 3 -41 -2 -66 -30 -129 l-35 -78 -22 73 c-27 90 -81 203 -116 243 l-26 30
23 -40 c12 -22 28 -49 35 -60 49 -76 107 -267 97 -318 l-5 -25 -27 31 c-54 62
-150 241 -195 361 -23 61 -24 65 -7 80 10 9 27 16 38 16 30 0 111 -93 158
-183 22 -41 41 -74 43 -72 10 10 -88 174 -131 221 l-50 55 22 30 c13 17 20 37
17 45 -10 26 49 -22 85 -69 44 -57 54 -67 39 -37 -7 13 -36 49 -64 79 -38 41
-43 49 -20 33 17 -12 48 -40 69 -63z m1810 -30 c39 -15 76 -40 118 -81 l62
-59 -50 5 c-27 3 -61 8 -75 11 -21 4 22 -11 90 -32 28 -9 -113 -54 -171 -54
-54 -1 -160 33 -146 47 3 3 -4 15 -15 26 -24 24 -18 34 12 18 11 -6 31 -8 43
-5 17 5 18 4 7 -4 -11 -7 -3 -13 35 -22 27 -7 44 -14 37 -17 -8 -2 -34 4 -58
13 -25 9 -48 14 -51 11 -4 -3 0 -6 8 -6 11 0 12 -2 1 -9 -15 -9 -6 -10 126
-12 46 0 86 2 89 5 4 3 -16 6 -43 7 -27 1 -60 5 -74 9 l-25 8 25 2 c18 1 9 6
-30 19 -30 10 -70 25 -88 35 -27 14 -31 20 -21 32 12 15 54 20 44 5 -7 -12 2
-17 45 -24 35 -6 35 -6 -5 8 -25 8 -32 14 -19 14 11 1 47 -8 79 -19 32 -11 60
-18 62 -16 6 5 -78 35 -141 49 -40 9 -54 16 -43 20 30 11 119 -12 190 -48 37
-19 67 -33 67 -31 0 15 -138 79 -191 89 -51 9 -58 13 -39 18 46 13 89 9 145
-12z m-2079 -97 c13 -61 81 -219 91 -209 2 3 -3 16 -11 30 -44 66 -93 247 -67
247 15 0 27 -22 55 -101 14 -41 51 -119 80 -172 30 -53 52 -97 49 -97 -3 0
-17 8 -31 19 -15 10 -44 26 -65 35 -31 12 -42 26 -63 73 -15 32 -34 88 -43
125 -10 37 -20 71 -24 75 -12 11 -8 45 5 41 6 -2 17 -32 24 -66z m1752 44 c62
-33 112 -111 112 -176 0 -39 -32 -120 -48 -120 -19 0 -71 42 -89 71 -23 39
-42 109 -20 75 6 -11 28 -36 47 -56 19 -20 25 -24 14 -10 -12 14 -30 38 -40
54 -20 32 -29 129 -11 123 7 -2 12 -10 12 -18 0 -19 48 -108 74 -139 12 -14 3
6 -19 45 -44 76 -49 95 -22 74 15 -13 16 -12 3 3 -7 10 -18 15 -24 11 -6 -4
-7 -1 -2 7 5 8 1 18 -10 27 -12 8 -14 13 -6 13 11 0 11 3 1 15 -16 19 -7 19
28 1z m-974 -66 c31 -17 69 -32 84 -34 15 -2 50 -15 79 -30 28 -14 66 -29 85
-32 42 -8 181 -43 184 -47 1 -1 -24 -95 -56 -208 -32 -114 -66 -241 -76 -283
-16 -70 -20 -76 -43 -76 -14 0 -35 3 -46 6 -20 5 -20 7 12 72 41 85 64 181 62
257 -1 33 -1 67 2 75 3 13 -13 15 -111 12 -113 -3 -193 -20 -235 -48 -11 -8
-23 -14 -27 -14 -3 0 -14 26 -23 57 -21 71 -45 119 -101 202 -24 35 -44 69
-44 76 0 7 20 15 48 19 26 4 65 16 87 25 24 11 44 15 51 10 6 -5 36 -22 68
-39z m-366 9 c36 -12 36 -28 1 -149 -30 -103 -32 -287 -5 -377 10 -35 23 -63
28 -63 5 0 5 7 -1 18 -5 9 -17 53 -26 97 -17 83 -15 184 7 290 12 60 66 195
78 195 5 0 11 -13 15 -29 3 -16 23 -53 44 -83 86 -120 125 -231 124 -355 -1
-66 -6 -93 -27 -138 -26 -57 -85 -140 -93 -131 -2 2 7 27 22 55 58 115 68 239
30 366 -10 34 -13 39 -10 15 31 -200 28 -251 -21 -361 -37 -81 -56 -76 -49 12
4 38 3 40 -4 14 -18 -64 -11 14 9 97 11 46 19 101 19 123 -1 34 -3 31 -10 -20
-5 -33 -15 -89 -23 -125 -9 -36 -17 -76 -19 -90 -3 -20 -5 -18 -6 8 0 19 8 80
20 135 22 108 22 246 0 297 -9 21 -10 19 -3 -15 12 -65 6 -214 -12 -299 -9
-43 -16 -101 -16 -130 l-1 -51 -24 45 c-13 25 -24 38 -25 30 -1 -26 -31 52
-51 135 -16 71 -18 74 -13 25 4 -30 19 -90 35 -134 26 -72 27 -78 9 -73 -10 3
-21 -1 -24 -9 -3 -7 -14 -12 -25 -11 -11 0 -18 4 -16 8 3 3 -7 17 -21 30 -14
13 -48 62 -76 109 -68 112 -107 161 -176 213 -66 50 -104 73 -52 31 90 -73
155 -145 202 -224 29 -47 64 -100 79 -119 16 -19 26 -38 23 -43 -7 -11 -81 28
-139 74 l-50 40 47 -46 c26 -25 42 -46 37 -46 -20 0 -95 58 -150 116 -65 69
-107 140 -144 244 -14 41 -31 80 -37 87 -19 23 0 27 59 13 92 -20 179 -59 241
-107 68 -53 102 -105 101 -153 -1 -19 8 -59 19 -87 11 -29 19 -53 17 -53 -2 0
-24 34 -50 75 -25 41 -49 75 -51 75 -6 0 -7 3 62 -110 45 -74 94 -135 94 -118
0 3 -11 26 -25 51 -73 131 -64 327 22 474 17 30 22 47 15 56 -5 6 -12 26 -15
42 -3 17 -9 38 -12 48 -8 20 0 21 43 6z m-426 -454 c42 -67 116 -142 180 -184
59 -40 85 -51 73 -31 -9 15 1 12 37 -10 18 -11 30 -20 26 -20 -3 0 -14 -3 -24
-7 -12 -4 -15 -3 -10 5 4 8 2 10 -7 6 -23 -8 -128 50 -193 107 l-59 51 64 -62
c65 -64 151 -112 201 -114 38 -1 27 -21 -13 -22 -17 -1 -26 1 -18 4 9 3 2 10
-19 18 -17 8 -36 11 -41 9 -21 -14 -140 70 -180 127 -12 17 -33 56 -45 87 -13
31 -24 49 -24 40 0 -9 14 -43 31 -77 43 -87 128 -161 230 -202 44 -18 82 -37
86 -43 4 -7 0 -8 -13 -3 -10 4 -53 19 -94 32 -41 14 -92 36 -113 50 -81 53
-132 160 -147 308 -5 50 -12 102 -15 116 -4 14 9 -13 27 -60 19 -47 45 -103
60 -125z m1064 130 c-3 -9 -12 -42 -21 -74 -43 -166 -141 -292 -280 -358 -41
-19 -78 -39 -81 -44 -8 -14 -34 -10 -34 5 0 7 19 21 43 30 43 16 120 72 82 59
-11 -3 3 15 32 41 29 26 67 66 85 89 20 26 3 13 -42 -33 -48 -48 -101 -89
-145 -112 -39 -21 -64 -38 -56 -38 8 0 34 12 58 26 25 14 47 23 50 21 3 -3
-17 -17 -44 -30 -51 -26 -114 -30 -121 -7 -3 9 1 11 13 6 23 -8 133 56 204
120 47 42 149 164 137 164 -2 0 -34 -36 -70 -79 -48 -59 -90 -96 -158 -140
-87 -58 -146 -85 -83 -38 16 12 43 35 60 50 16 16 6 10 -23 -12 -30 -23 -58
-41 -64 -41 -6 0 10 24 34 53 41 47 69 71 148 130 25 18 25 18 -5 1 -16 -9
-44 -27 -62 -40 -17 -13 -34 -24 -36 -24 -3 0 0 16 6 36 7 23 25 45 49 62 68
47 77 55 48 41 -14 -6 -37 -20 -51 -30 l-26 -18 -2 68 -2 69 47 22 c66 33 95
38 213 39 89 1 102 -1 97 -14z m-11 -171 c-30 -137 -112 -268 -198 -318 -46
-27 -187 -70 -187 -57 0 5 15 11 33 15 20 4 27 9 20 14 -8 5 4 13 31 21 69 20
166 90 205 146 34 50 34 50 -24 -13 -58 -64 -128 -107 -214 -133 -35 -10 -40
-14 -25 -20 17 -7 17 -8 -2 -8 -12 -1 -27 5 -33 13 -9 11 -4 15 26 21 40 7
156 55 150 62 -2 2 -28 -8 -58 -22 -30 -14 -61 -25 -69 -24 -8 0 1 6 21 14 76
28 118 56 185 128 71 74 110 141 135 227 20 71 23 19 4 -66z m535 72 c143 -31
210 -76 348 -240 l75 -89 -34 6 c-69 14 -180 80 -237 142 -63 69 -60 55 6 -22
l44 -53 -27 0 c-16 0 -62 20 -104 44 -47 27 -93 45 -121 48 -25 3 -76 20 -115
39 -38 18 -74 35 -80 37 -5 2 6 -9 25 -26 l35 -30 -92 5 c-67 4 -97 2 -108 -7
-9 -7 -18 -10 -21 -8 -12 12 63 45 99 44 32 -2 37 1 35 19 -4 27 37 33 65 8
l22 -18 -19 23 c-30 35 2 28 43 -10 22 -21 28 -23 15 -8 -41 49 -37 54 9 13
45 -41 71 -57 48 -30 -6 8 -9 16 -6 19 3 3 -5 13 -17 24 l-23 19 19 -22 c22
-27 11 -31 -16 -6 -29 26 -22 33 32 33 28 0 50 -3 50 -7 0 -5 11 -21 25 -38
18 -21 23 -24 19 -10 -5 14 3 9 25 -15 l32 -35 -27 40 c-39 55 -46 63 -39 40
l7 -20 -17 20 c-15 19 -15 20 2 24 11 2 27 -9 43 -29 29 -39 97 -112 83 -90
-5 8 -25 34 -43 57 -18 24 -31 45 -29 47 11 11 87 -27 111 -56 15 -18 31 -33
37 -33 6 0 22 -15 36 -34 29 -40 35 -33 7 7 -32 46 -35 58 -6 27 15 -16 31
-30 35 -30 4 0 11 -4 16 -9 5 -5 2 -6 -7 -1 -12 7 -13 6 -2 -6 7 -8 17 -14 21
-14 4 0 20 -13 35 -30 39 -42 20 -10 -26 47 -52 62 -116 103 -208 132 -157 50
-270 43 -405 -24 l-80 -39 50 44 c58 52 105 72 195 84 92 12 88 12 160 -3z
m-458 -339 c58 -27 173 -113 161 -120 -5 -3 -45 -16 -89 -30 -117 -37 -237
-51 -320 -38 -81 12 -96 7 -24 -8 96 -21 228 -6 367 40 55 18 65 19 89 6 l27
-14 -59 -38 c-106 -69 -183 -99 -257 -103 -43 -3 -73 0 -80 7 -6 6 -15 11 -21
11 -5 0 4 -15 21 -32 57 -60 86 -133 91 -228 2 -47 -1 -103 -5 -125 l-9 -40
-38 80 c-38 82 -86 141 -144 177 -54 33 -46 14 8 -21 56 -36 101 -95 132 -174
12 -28 30 -65 41 -80 11 -16 17 -30 15 -32 -2 -2 -37 0 -77 6 -52 6 -97 20
-149 46 -88 43 -178 125 -167 151 5 9 8 24 9 33 0 10 27 -10 71 -55 64 -64
217 -167 230 -154 3 2 -30 24 -73 48 -78 44 -222 175 -222 201 0 8 13 1 28
-16 15 -17 40 -42 57 -55 16 -14 3 4 -28 40 -32 35 -56 71 -55 80 1 8 -1 21
-6 28 -14 23 148 -90 203 -142 l54 -51 -33 44 c-19 24 -70 68 -115 96 -89 58
-117 85 -134 130 -7 20 -9 22 -5 5 3 -14 8 -64 11 -111 7 -114 -14 -178 -109
-330 -39 -63 -80 -135 -91 -161 -30 -74 -40 -50 -35 81 4 79 2 105 -4 81 -5
-19 -10 -73 -11 -120 -1 -47 -5 -87 -9 -90 -10 -8 -46 44 -80 117 -26 55 -33
83 -36 153 -4 74 -2 87 15 102 19 17 19 15 19 -75 0 -50 5 -112 12 -136 19
-64 22 -46 5 34 -21 99 -7 208 35 273 21 32 25 21 7 -21 -24 -58 -16 -57 11 2
25 55 31 77 14 51 -5 -8 -10 -10 -10 -5 0 6 5 17 10 25 9 13 10 13 10 0 1 -12
3 -11 11 3 6 9 7 27 3 39 -5 18 -11 12 -39 -41 -83 -156 -164 -215 -355 -260
-71 -17 -134 -31 -140 -31 -24 0 -7 18 33 35 56 25 171 98 229 147 61 50 149
168 106 142 -7 -4 -8 -3 -4 5 4 6 11 9 16 6 9 -6 32 28 28 42 -1 4 10 31 25
58 24 46 26 48 27 22 0 -54 -78 -186 -160 -269 -42 -42 -70 -74 -63 -69 8 4
41 34 75 67 55 54 148 192 148 221 0 6 5 15 11 19 27 17 -35 -118 -82 -177
-17 -22 -28 -39 -25 -39 15 0 96 125 111 171 14 41 19 48 27 35 5 -9 16 -16
24 -16 11 0 13 -6 9 -20 -3 -10 -1 -28 5 -40 9 -16 4 -37 -24 -108 -19 -48
-37 -112 -40 -142 -6 -53 -5 -52 12 20 10 41 30 102 44 135 15 33 29 78 33
100 10 61 15 75 27 75 17 0 2 -119 -22 -181 -49 -124 -69 -193 -74 -252 l-5
-62 15 78 c8 43 33 126 55 184 22 58 43 131 47 162 6 44 11 57 26 59 18 4 19
-1 13 -79 -3 -55 -19 -129 -45 -214 -21 -71 -42 -148 -45 -170 -5 -36 -4 -35
9 10 8 28 31 104 51 170 25 81 38 148 42 205 3 62 7 77 12 55 l7 -30 2 35 c1
29 3 32 10 15 5 -11 9 -42 10 -70 2 -59 7 -50 13 22 2 29 0 50 -5 47 -4 -3 -8
4 -8 15 0 12 6 21 13 22 6 0 19 2 28 4 10 2 37 -21 68 -56 29 -32 62 -64 74
-70 12 -6 4 5 -18 24 -39 34 -112 127 -100 127 17 0 121 -56 161 -87 25 -19
48 -33 50 -31 7 8 -75 65 -146 100 -41 21 -67 38 -57 38 23 0 121 -50 174 -89
37 -27 80 -74 136 -151 12 -17 11 -11 -4 17 -33 63 -135 158 -211 196 l-66 34
36 13 c36 13 37 14 12 22 -17 5 -13 6 15 2 22 -3 70 -20 107 -38 57 -27 75
-31 115 -26 l48 6 -62 7 c-34 5 -65 12 -67 16 -3 5 14 5 37 0 69 -13 121 -10
192 12 37 11 65 22 63 25 -3 2 -25 -3 -49 -12 -64 -24 -194 -22 -302 4 -49 12
-99 22 -113 22 -13 0 -24 5 -24 11 0 6 8 8 18 6 53 -15 124 -29 127 -25 3 2
-29 12 -70 22 -41 10 -75 21 -75 25 0 16 96 19 155 5 33 -7 87 -13 120 -13
l60 2 -75 8 c-41 5 -109 15 -150 22 l-75 14 41 13 c32 10 56 10 105 1 72 -12
92 -7 27 7 -23 5 -34 10 -23 11 11 0 47 -6 80 -13 33 -8 85 -17 115 -20 30 -3
-2 6 -72 21 -71 14 -128 29 -128 33 0 4 14 14 32 21 28 11 44 10 137 -14 58
-15 113 -27 121 -26 15 1 -139 44 -197 55 -54 11 -25 27 36 21 l56 -5 -46 12
c-31 7 -44 15 -41 25 7 21 110 8 174 -22z m-962 -158 c-12 -8 -9 -9 13 -5 15
4 27 2 27 -3 0 -17 -19 -22 -39 -12 -11 6 -21 7 -23 3 -2 -4 -27 -24 -55 -45
-85 -62 -153 -157 -177 -247 -4 -14 9 7 28 47 38 80 108 161 175 205 59 37 75
36 23 -2 -23 -17 -42 -33 -42 -37 0 -3 8 1 18 9 29 26 98 57 110 50 6 -4 -18
-22 -56 -42 -66 -34 -148 -105 -172 -149 -8 -15 -8 -21 -1 -17 6 4 11 3 11 -3
0 -5 -5 -13 -11 -17 -14 -8 -103 -193 -97 -200 3 -2 20 28 38 68 64 142 160
247 282 309 75 38 32 -38 -96 -170 l-81 -85 69 62 c38 34 82 80 99 102 17 22
33 37 35 35 11 -11 -53 -88 -135 -164 -50 -45 -100 -96 -113 -114 -29 -39 183
161 222 210 28 36 30 41 32 77 1 23 15 29 35 17 11 -7 9 -17 -13 -56 -29 -53
-31 -59 -14 -49 7 4 8 3 4 -5 -4 -6 -11 -9 -16 -6 -13 8 -31 -21 -24 -39 9
-24 -124 -152 -219 -211 -71 -43 -204 -101 -213 -92 -2 2 5 26 15 53 10 27 35
101 56 165 57 178 126 274 243 337 55 30 93 43 62 21z m2256 -352 c-10 -7 -34
-22 -53 -34 -18 -13 -48 -23 -65 -23 -24 0 -28 -3 -20 -13 17 -20 -31 -44
-113 -57 -39 -6 -88 -18 -110 -25 -50 -17 -181 -29 -189 -17 -8 13 154 132
180 132 4 0 0 -8 -8 -17 -28 -33 -32 -49 -8 -28 l23 20 -18 -28 c-10 -15 -25
-27 -34 -25 -9 1 -23 -1 -31 -6 -12 -7 -13 -6 -2 5 6 7 12 18 12 24 -1 5 -12
-3 -25 -20 -14 -16 -39 -35 -57 -42 l-33 -11 33 -1 c17 -1 32 3 32 9 0 5 15 8
34 6 19 -1 33 1 30 6 -3 4 4 8 16 8 12 0 19 4 16 9 -7 10 48 26 61 18 5 -3 35
-2 68 3 58 7 59 7 15 9 -36 1 -42 4 -27 12 9 6 27 7 40 4 18 -5 19 -4 7 5 -11
8 -5 9 25 5 26 -4 35 -3 27 3 -11 7 -10 10 3 16 9 3 28 6 41 6 13 0 24 5 24
11 0 7 -9 9 -22 5 -70 -19 -71 -19 -62 -5 4 7 -5 3 -21 -9 -21 -17 -30 -19
-36 -10 -4 7 -3 19 4 28 6 8 -7 -2 -28 -22 -42 -39 -102 -63 -93 -37 3 8 10
14 16 14 5 0 14 3 18 7 3 4 -4 5 -17 3 l-24 -4 23 19 c36 30 182 54 182 30 0
-7 6 -7 18 0 9 5 44 14 77 19 85 12 94 12 71 -2z m-1719 -147 c41 -36 92 -64
176 -99 27 -11 27 -12 22 -73 -2 -35 -7 -97 -11 -140 l-6 -76 -26 8 c-15 5
-48 16 -73 25 l-46 16 -18 87 c-10 48 -31 133 -46 190 -15 56 -25 102 -22 102
3 0 25 -18 50 -40z m1390 -72 c56 -12 103 -24 103 -28 0 -4 -19 -15 -42 -26
-24 -10 -46 -23 -49 -27 -4 -5 -38 -12 -77 -15 -65 -4 -75 -2 -126 27 -30 17
-61 28 -69 25 -8 -3 -19 1 -26 9 -18 23 28 30 59 10 24 -16 24 -16 11 1 -14
16 -12 17 15 14 16 -2 29 -9 29 -17 0 -7 11 -21 25 -30 24 -15 24 -15 10 3
-15 19 -14 19 17 3 50 -26 53 -20 5 9 -24 14 -41 29 -38 32 4 3 12 0 18 -6 15
-15 32 -16 23 -2 -8 13 -2 13 29 -1 14 -7 23 -16 19 -21 -3 -6 4 -5 15 1 11 6
33 12 49 12 16 1 1 7 -37 15 -114 23 -214 17 -365 -22 -19 -5 -19 -4 -1 7 85
53 232 63 403 27z m-693 -65 c-14 -62 -53 -131 -88 -158 -25 -19 -33 -31 -25
-35 8 -5 4 -10 -10 -15 -13 -4 -20 -9 -18 -12 8 -8 48 17 42 26 -2 5 7 16 21
26 l25 16 -16 -28 c-14 -25 -35 -57 -80 -121 -9 -12 -20 -20 -25 -17 -5 3 -12
0 -16 -6 -4 -7 -3 -9 3 -6 6 4 14 0 17 -9 3 -8 2 -13 -3 -10 -5 3 -18 -9 -30
-27 -23 -36 -27 -32 -10 10 7 15 13 38 15 49 3 30 32 84 44 84 5 0 7 6 4 14
-3 8 13 33 37 57 24 24 42 48 40 53 -1 5 7 18 17 30 29 32 36 47 18 40 -9 -3
-16 -10 -16 -15 0 -5 -5 -8 -11 -6 -6 1 -16 -13 -22 -32 -14 -39 -33 -51 -40
-24 -3 10 -5 2 -6 -18 0 -20 -7 -42 -15 -48 -12 -10 -15 -7 -19 16 -4 26 -4
25 -6 -7 -3 -33 -4 -33 -11 -10 -7 21 -8 22 -9 5 -1 -12 4 -26 10 -32 8 -8 8
-17 1 -29 -9 -16 -12 -14 -21 16 -9 31 -10 29 -5 -21 4 -36 2 -59 -5 -63 -7
-5 -9 4 -6 26 l5 33 -13 -35 c-12 -31 -14 -32 -19 -12 -8 29 6 77 35 126 22
35 192 194 210 195 4 1 5 -11 1 -26z m1011 -210 c-79 -59 -169 -77 -258 -52
-48 13 -62 4 -16 -10 l34 -11 -40 6 c-22 3 -53 11 -70 18 -27 12 -21 13 80 20
61 4 126 11 145 16 32 9 30 9 -15 5 -27 -2 -120 -9 -205 -15 -332 -23 -559
-89 -735 -215 -33 -24 -96 -81 -140 -126 -44 -46 -70 -75 -58 -65 14 12 31 16
45 13 21 -5 21 -5 6 7 -17 12 -17 14 0 29 11 10 27 14 42 10 24 -5 25 -5 5 10
-25 20 -22 20 39 2 27 -8 59 -15 70 -14 12 0 -4 7 -34 16 -30 8 -59 18 -64 23
-15 14 41 43 71 36 l28 -6 -25 16 c-14 8 -17 13 -7 10 121 -38 159 -47 169
-44 7 3 -19 12 -57 22 -93 23 -105 30 -83 52 15 16 19 16 57 0 59 -23 75 -19
21 5 l-45 21 29 15 c32 17 42 15 141 -20 39 -14 86 -28 105 -31 19 -4 -12 10
-70 29 -129 44 -167 72 -53 40 10 -3 5 1 -11 9 l-30 13 29 16 c27 14 34 13 79
-4 27 -11 54 -18 60 -16 6 2 -7 10 -29 17 -22 7 -44 17 -50 22 -16 16 41 3
119 -26 38 -15 70 -25 72 -23 2 2 -30 17 -72 33 -70 26 -73 29 -48 36 20 5 53
0 105 -17 86 -27 201 -49 221 -43 7 3 -18 10 -57 17 -91 16 -215 52 -215 62 0
11 76 22 101 14 14 -5 19 -4 15 4 -10 15 6 14 71 -5 65 -19 82 -13 21 7 -41
12 -41 13 -15 17 16 2 46 -4 68 -12 55 -22 72 -19 24 3 l-40 19 31 1 c17 1 51
-6 75 -15 24 -9 73 -17 109 -19 36 -1 83 -3 105 -4 35 -1 37 -3 20 -13 -11 -6
-27 -19 -35 -28 -22 -27 -125 -66 -208 -80 -40 -6 -80 -19 -89 -28 -18 -19
-105 -23 -158 -9 -16 5 -27 5 -23 1 4 -4 29 -12 55 -19 l47 -12 -26 -17 c-28
-18 -144 -46 -195 -46 -17 0 -38 -7 -47 -15 -21 -21 -65 -19 -181 9 -55 14
-102 23 -103 21 -6 -6 98 -35 171 -47 124 -22 70 -38 -128 -38 -74 0 -135 -4
-137 -8 -5 -13 -102 -42 -111 -33 -11 11 72 119 137 179 157 142 401 241 656
263 123 11 512 27 520 21 3 -2 -4 -10 -15 -19z m-1701 2 c65 -17 131 -43 224
-88 63 -31 117 -88 157 -165 22 -44 12 -53 -13 -12 -12 19 -22 26 -24 19 -2
-7 -17 10 -33 39 -30 55 -48 66 -20 13 22 -43 1 -40 -43 7 -23 23 -33 30 -28
17 9 -25 -3 -12 -38 40 -31 47 -35 35 -5 -14 l20 -33 -38 7 c-43 7 -86 32 -79
44 3 4 -16 28 -42 52 l-47 44 45 -47 c60 -64 39 -61 -29 5 -31 28 -44 39 -31
22 14 -16 41 -44 60 -62 30 -28 32 -32 15 -33 -22 0 -52 18 -100 59 -26 23
-26 21 4 -12 32 -34 33 -37 13 -37 -12 0 -47 21 -79 46 l-58 47 40 -42 39 -41
-31 0 c-29 0 -90 37 -79 48 3 3 16 -3 28 -14 13 -10 10 -4 -6 14 -29 31 -61
47 -36 17 7 -8 9 -15 4 -15 -4 0 -3 -5 4 -12 24 -24 11 -29 -20 -7 -25 18 -29
19 -18 5 14 -17 11 -18 -65 -13 -76 6 -77 6 -26 -4 38 -8 50 -14 40 -20 -20
-12 -2 -11 23 2 27 14 36 3 12 -17 -17 -13 -16 -13 13 1 37 17 69 20 78 5 3
-5 2 -10 -2 -10 -5 0 -18 -7 -29 -15 -12 -10 -4 -8 26 4 35 16 58 19 110 14
92 -8 102 -16 52 -42 -22 -11 -44 -26 -47 -31 -4 -6 17 3 47 19 53 30 94 37
131 22 15 -5 9 -11 -32 -31 -28 -13 -59 -33 -68 -44 -18 -20 -18 -20 10 -3 54
34 120 67 135 67 9 0 -5 -13 -31 -30 -65 -41 -59 -47 9 -9 56 32 56 32 92 14
l37 -17 -28 -14 c-39 -21 -33 -27 9 -9 34 14 39 14 69 -5 67 -41 62 -45 -61
-45 -63 0 -114 3 -114 6 0 4 21 21 48 38 26 17 43 31 39 31 -14 0 -63 -28 -76
-44 -21 -25 -163 -22 -199 3 -15 10 -45 22 -68 25 -23 3 -56 12 -74 20 l-33
13 42 23 c22 13 39 26 36 28 -2 2 -21 -5 -41 -17 -22 -13 -45 -19 -58 -16 -38
11 -101 39 -111 51 -5 6 -39 22 -75 35 l-65 24 70 11 c159 27 199 36 219 47
18 10 94 31 131 36 6 0 37 -6 69 -14z m712 -128 c-84 -45 -187 -128 -246 -199
-30 -36 -56 -65 -58 -63 -7 6 62 133 115 212 l55 82 93 -2 93 -2 -52 -28z
m-1420 -35 c30 -20 49 -132 25 -146 -5 -4 -12 -24 -16 -46 l-7 -40 -41 43
c-23 24 -32 31 -19 17 32 -38 27 -49 -7 -16 -29 28 -47 76 -28 76 5 0 4 6 -3
14 -21 26 -8 61 24 64 17 2 26 0 22 -6 -3 -6 8 -20 26 -32 l33 -22 -28 26
c-31 29 -24 34 13 10 22 -14 22 -12 -3 10 -18 16 -40 26 -59 26 -26 0 -29 2
-18 15 13 16 67 20 86 7z m-230 -23 c3 -6 0 -18 -9 -26 -11 -12 -19 -12 -35
-4 -11 6 -29 11 -39 10 -10 0 -3 -5 15 -10 30 -9 51 -33 40 -44 -2 -3 -22 4
-43 15 -22 11 -53 20 -70 19 -26 0 -23 -2 17 -13 87 -23 134 -75 51 -55 -45
10 -124 12 -148 3 -24 -10 1 -24 43 -24 20 0 31 -4 27 -10 -3 -5 -39 -11 -78
-11 -40 -1 -85 -5 -102 -9 -18 -5 -1 -6 44 -3 60 5 73 3 69 -8 -6 -17 -61 -37
-108 -39 -32 -2 -33 -3 -10 -9 23 -7 23 -8 5 -13 -11 -4 -37 -9 -58 -13 -22
-3 -35 -10 -31 -16 4 -7 -3 -10 -18 -8 -29 4 -104 -48 -147 -101 -26 -32 -42
-41 -111 -59 -80 -22 -162 -22 -176 0 -3 5 -1 9 5 9 5 0 17 3 27 7 13 5 15 3
9 -8 -6 -9 0 -8 19 5 18 11 25 13 21 5 -5 -8 3 -4 16 8 24 21 70 43 92 43 6 0
-2 -15 -19 -32 -16 -19 -7 -14 20 12 43 39 47 41 31 15 -18 -30 -18 -30 2 -12
11 9 22 14 25 11 3 -3 9 0 14 7 4 8 3 10 -4 5 -19 -11 -14 9 6 26 33 29 46 33
39 12 -5 -14 0 -11 18 9 13 15 27 27 30 27 3 0 16 7 30 16 13 9 28 13 34 10 5
-4 20 14 32 40 13 25 49 68 80 95 l57 49 83 1 c78 1 81 2 39 10 -25 5 -60 7
-77 4 -18 -3 -33 -4 -33 -1 0 8 60 38 105 52 50 16 161 18 171 3z m98 5 c21
-8 20 -28 -1 -45 -20 -17 -73 -99 -73 -113 1 -6 8 2 16 18 11 19 19 25 24 17
5 -8 13 -3 25 16 l18 28 -21 -19 c-31 -27 -27 -5 3 24 l25 23 0 -45 c0 -25 -9
-65 -20 -89 -11 -24 -19 -57 -19 -74 1 -23 3 -19 13 20 25 102 45 94 47 -20 1
-47 3 -62 8 -45 8 25 8 24 24 -12 15 -37 36 -55 23 -22 -5 14 -4 15 9 4 25
-21 17 -1 -10 24 -14 13 -28 38 -32 57 l-6 34 24 -20 c20 -16 19 -14 -3 11
-16 16 -28 39 -28 49 0 17 2 17 23 -3 13 -12 26 -20 29 -16 13 12 26 -34 31
-106 3 -42 9 -84 13 -94 6 -17 4 -18 -21 -12 -31 8 -83 40 -102 64 -7 9 -13
27 -13 41 0 15 3 21 8 14 4 -6 7 11 7 37 0 62 -12 42 -21 -33 -6 -53 -8 -57
-24 -42 -9 8 -21 15 -26 15 -15 0 -41 61 -49 112 -8 50 2 76 42 119 13 14 23
32 21 39 -2 7 8 17 22 21 22 6 23 7 4 8 -12 1 -32 -8 -45 -19 -18 -15 -20 -18
-6 -14 14 5 11 -4 -15 -38 -18 -25 -34 -44 -36 -43 -1 1 2 23 7 49 13 72 51
101 105 80z m238 -80 l46 -15 -15 -31 c-9 -17 -16 -57 -17 -88 l-1 -58 -55
-10 c-30 -5 -57 -8 -59 -6 -6 6 20 137 29 149 4 6 11 25 15 43 3 17 8 32 9 32
1 0 23 -7 48 -16z m309 -7 c19 -12 34 -30 34 -38 0 -8 8 -23 18 -33 26 -27 17
-28 -41 -6 -29 11 -62 20 -75 20 -12 -1 13 -12 55 -27 l78 -26 20 -54 c27 -71
25 -78 -9 -53 -16 11 -49 27 -72 35 -48 17 -60 19 -39 5 13 -8 12 -10 -3 -10
-27 0 -57 19 -57 35 0 22 38 18 100 -10 44 -20 50 -21 30 -6 -14 11 -51 27
-82 36 -32 9 -60 19 -62 24 -3 4 -8 27 -12 51 l-7 43 57 -5 c37 -3 52 -2 42 4
-9 5 -34 10 -56 11 -46 1 -49 4 -17 17 36 15 62 11 98 -13z m-191 5 c0 -5 -9
-17 -21 -28 -11 -10 -26 -28 -31 -38 -6 -11 7 -1 29 22 59 62 98 55 52 -9 -27
-38 -59 -131 -58 -166 0 -18 6 -8 15 29 14 62 47 132 65 143 6 4 4 -7 -5 -23
-20 -38 -21 -52 -2 -26 28 38 33 4 20 -143 -4 -54 -3 -83 4 -83 14 0 23 43 10
51 -7 4 -8 23 -4 54 12 79 30 38 30 -67 -1 -71 0 -77 3 -25 5 84 23 88 24 5 2
-55 3 -58 8 -23 l6 39 13 -27 c6 -15 13 -54 14 -85 l1 -57 6 45 c6 42 7 44 19
23 6 -12 12 -39 13 -60 0 -21 5 -47 10 -58 7 -15 8 -9 5 23 -2 23 -3 42 -1 42
2 0 17 -24 34 -52 28 -48 45 -62 21 -18 -9 16 -7 19 17 17 24 -2 29 -8 35 -42
4 -22 7 -55 7 -74 l1 -34 -51 52 c-27 28 -56 51 -63 51 -8 0 -30 22 -51 48
-36 48 -90 89 -111 84 -6 -1 -19 7 -29 18 -23 26 -24 145 -1 209 19 51 19 54
7 46 -16 -9 -44 -154 -38 -197 l6 -41 -30 30 c-22 21 -35 50 -49 107 -24 95
-25 147 -4 191 17 37 74 72 74 47z m861 -60 l22 -19 -38 -116 c-34 -104 -55
-143 -55 -104 0 7 -16 28 -35 47 -19 19 -35 39 -35 45 0 7 -9 20 -20 30 -16
14 -20 31 -20 77 l0 58 79 0 c62 0 84 -4 102 -18z m-610 -164 c26 -15 49 -25
52 -22 8 8 20 -54 22 -116 1 -30 6 -72 10 -93 l8 -39 -29 15 c-16 8 -34 12
-40 10 -6 -2 -19 8 -28 22 -19 29 -7 32 32 8 26 -17 27 -17 12 2 -14 17 -13
18 5 12 l20 -7 -20 16 c-11 9 -38 25 -61 35 -45 20 -83 76 -44 66 29 -7 25 2
-6 14 -15 5 -24 12 -21 16 4 3 30 -6 58 -21 29 -14 46 -20 40 -12 -7 8 -36 25
-66 37 -47 19 -75 48 -75 77 0 19 88 5 131 -20z m-336 -68 c4 -17 11 -28 16
-25 5 4 4 -6 -2 -22 -19 -52 -114 -136 -128 -114 -5 8 -20 2 -47 -20 -41 -33
-94 -63 -94 -54 0 3 11 22 25 43 25 39 59 112 51 112 -3 0 -22 -32 -42 -71
-36 -68 -85 -119 -115 -119 -8 0 -40 -22 -71 -50 -31 -27 -66 -50 -77 -50 -21
0 -21 1 -6 30 8 16 15 35 15 42 -1 7 -12 -9 -26 -37 -26 -50 -112 -147 -148
-167 -18 -10 -18 -7 -12 43 14 108 22 123 76 153 27 15 50 31 50 35 0 10 42
23 49 15 3 -2 -26 -36 -64 -74 -38 -39 -66 -70 -62 -70 4 0 0 -12 -9 -27 -16
-27 -15 -27 10 2 14 17 26 26 26 20 1 -5 12 12 26 39 17 32 29 45 33 37 6 -8
12 -3 20 17 7 16 26 43 43 58 l30 29 -7 -25 c-4 -14 -14 -41 -21 -60 -12 -31
-12 -33 1 -16 8 10 22 42 31 70 10 28 24 57 33 64 33 27 62 45 65 41 3 -2 -7
-31 -20 -64 -13 -33 -17 -51 -9 -41 8 10 22 42 31 70 15 48 55 99 62 78 1 -5
-7 -33 -18 -62 -11 -29 -19 -54 -17 -57 5 -5 47 104 47 123 0 7 9 18 20 24 25
13 25 12 0 -44 -28 -65 -15 -57 18 11 26 55 53 76 73 56 4 -4 -1 -28 -12 -52
-10 -24 -18 -46 -15 -48 2 -2 14 19 27 46 12 28 27 51 32 51 5 0 6 11 3 25 -4
14 -4 25 -2 25 2 0 12 3 21 6 13 5 15 1 10 -22 -4 -25 -3 -25 9 6 19 47 20 14
1 -40 -8 -24 -13 -45 -11 -47 2 -2 12 17 21 44 29 81 34 93 44 93 5 0 12 -14
16 -30z"/>
</g>
</Svg>

  );
};

export default Icon;
